import amplitude from "amplitude-js";
import helpers from "helpers/base";
import amplitudeJSON from "../../constants/amplitude/index.json";
import {
  MD_PIXEL_MAX,
  SM_PIXEL_MAX,
  XS_PIXEL_MAX,
  XXS_PIXEL_MAX,
} from "../../constants/breakpoints";

export async function initialize() {
  initAmplitude();
  setUser();
  setLastTouchProperties({
    ...window.amplitudeLastTouchProperties,
    ...lastContentPageType(),
  });
  document.addEventListener("DOMContentLoaded", observeDOMChanges);
  document.addEventListener("DOMContentLoaded", setCtaHandlers);
  document.addEventListener("DOMContentLoaded", setupExposureLogging);

  if (!window.location.pathname.match(/^\/admin/)) {
    logPageViewed();
  }
  if (window.location.pathname.match(/^\/sign-up/)) {
    logSignUpPageViewed();
  }
  if (window.amplitudeAccountCreated) {
    logAccountCreated();
  }
  if (window.amplitudeSubscriptionStarted) {
    logSubscriptionStarted(window.amplitudeSubscriptionStartedText);
  }
}

function observeDOMChanges() {
  const observer = new MutationObserver(setCtaHandlers);
  observer.observe(document, { childList: true, subtree: true });
}

// This function is used to log an Amplitude Link/CTA event. It is
// meant to be similar to amplitudeEventClickHandler, but can be used
// at will (e.g. onKeyDown handlers) instead of using the data-amplitude property.
//
// NOTE: It currently does not support data-amplitude-text or data-amplitude-funnel.
// If needed, someone can update this function to accomodate.
export const logAmplitudeCtaEvent = (amplitudeKey) => {
  if (amplitudeKey && !amplitudeJSON[amplitudeKey]) return;

  const dataAttributes = amplitudeJSON[amplitudeKey];

  setLastTouchProperties({
    lastLinkOrCTACategory: dataAttributes.linkOrCTACategory,
    lastLinkOrCTASubcategory: dataAttributes.linkOrCTASubcategory,
  });
  logEvent("Link or CTA Clicked", dataAttributes);
};

const amplitudeEventClickHandler = (event) => {
  const element = event.currentTarget;
  const dataAttribute = element.getAttribute("data-amplitude");
  const dataTextAttribute = element.getAttribute("data-amplitude-text");
  const dataFunnelAttribute = element.getAttribute("data-amplitude-funnel");

  if (dataAttribute && !amplitudeJSON[dataAttribute]) return;

  const dataAttributes = amplitudeJSON[dataAttribute];

  if (dataTextAttribute) {
    dataAttributes.textInCTAorLink = dataTextAttribute;
  }
  if (dataFunnelAttribute) {
    dataAttributes.stepIntoSubscriptionFunnel = dataFunnelAttribute;
  }
  setLastTouchProperties({
    lastLinkOrCTACategory: dataAttributes.linkOrCTACategory,
    lastLinkOrCTASubcategory: dataAttributes.linkOrCTASubcategory,
  });
  logEvent("Link or CTA Clicked", dataAttributes);
};

function setCtaHandlers() {
  // Get all elements with data-amplitude=KEY
  const elements = document.querySelectorAll("[data-amplitude]");

  // Iterate over each element and add a click event listener
  elements.forEach((element) => {
    // Remove existing click event listeners
    element.removeEventListener("click", amplitudeEventClickHandler);

    // Add click event listener
    element.addEventListener("click", amplitudeEventClickHandler);
  });
}

// Observes [data-amplitude-log-exposure-on=viewport] elements, and when they enter the viewport, it logs an Amplitude
// exposure event for the given data-amplitude-experiment and data-amplitude-variant attributes. Supports AB testing.
function setupExposureLogging() {
  const elements = document.querySelectorAll(
    "[data-amplitude-log-exposure-on=viewport]"
  );
  if (elements.length === 0) {
    return;
  }

  const exposureObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      const isInViewport = entry.intersectionRatio > 0;
      if (isInViewport) {
        // Stop observing the element as the exposure event only needs to be logged once.
        observer.unobserve(entry.target);
        const request = {
          method: "POST",
          headers: {
            "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
              .content,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            experiment: entry.target.dataset.amplitudeExperiment,
            variant: entry.target.dataset.amplitudeVariant,
          }),
        };
        fetch("/exposure_events.json", request).then((response) => {
          entry.target.dataset.amplitudeLoggedExposure = response.ok; // eslint-disable-line no-param-reassign
        });
      }
    });
  });

  elements.forEach((element) => {
    exposureObserver.observe(element);
  });
}

function initAmplitude() {
  a().init(window.amplitudeApiKey);
}

export function setUser() {
  if (window.amplitudeUser) {
    a().setUserId(window.amplitudeUser.id);
    a().setUserProperties(window.amplitudeUser.properties);
  }
}

function setLastTouchProperties(properties) {
  if (pageType() !== "sign-up") {
    a().setUserProperties(properties);
  }
}

function lastContentPageType() {
  const contentPageTypes = [
    "ai",
    "lit",
    "poetry",
    "literary-devices-and-terms",
    "shakescleare",
  ];
  const page = pageType();
  if (contentPageTypes.includes(page)) {
    return { lastContentPageType: page };
  }
  return {};
}

// Amplitude JS docs: https://www.docs.developers.amplitude.com/data/sdks/javascript/
// convenience method for amplitude.getInstance()
// and allows us to mock amplitude for testing
function a() {
  if (window.amplitudeApiKey) {
    // Amplitude API key is set implying this is not a test environment, so return the real deal:
    return amplitude.getInstance();
  }

  // Amplitude API key is NOT set, so this must be a test environment, so return a stub:
  window.amplitude = window.amplitude || {
    calls: JSON.parse(sessionStorage.getItem("amplitudeStub.calls") || "[]"),
    userId: null,
    _reset_stub() {
      this.userId = null;
      this.calls = [];
      sessionStorage.setItem("amplitudeStub.calls", JSON.stringify(this.calls));
    },
    addCall(call) {
      this.calls.push(call);
      sessionStorage.setItem("amplitudeStub.calls", JSON.stringify(this.calls));
    },
    getUserId() {
      this.addCall(["getUserId"]);
      return this.userId;
    },
    init(_apiKey) {
      this.addCall(["init"]);
    },
    logEvent(e, properties) {
      this.addCall(["logEvent", e, properties]);
    },
    regenerateDeviceId() {
      this.addCall(["regenerateDeviceId"]);
    },
    setUserId(id) {
      this.addCall(["setUserId", id]);
      this.userId = id;
    },
    setUserProperties(properties) {
      this.addCall(["setUserProperties", properties]);
    },
  };

  return window.amplitude;
}

export function logoutUser() {
  a().setUserId(null); // not string 'null'
  a().regenerateDeviceId();
}

function defaultProperties() {
  const pageProperties = window.amplitudePageProperties || {};

  return {
    ...pageProperties,
    // gradeLevel = grade levels assigned to the guide. Every text has multiple grade levels associated with it; should capture all such grade levels individuall if possible, or in terms of a range if not.
    // gradeLevel: pageProperties.gradeLevel,

    // guideSlug = slug of guide after last ""/"" in URL (only fires on guide pages: lit guides; poetry guides; literary terms guides; shakescleare guides)
    // guideSlug: pageProperties.guideSlug,

    // guideTitle = title of guide (only fires on guide pages: lit guides; poetry guides; literary terms guides; shakescleare guides)
    // guideTitle: pageProperties.guideTitle,

    // isPD = yes or no; yes if marked as public domain, no if otherwise (only fires for poetry guides)
    // isPD: pageProperties.isPD,

    // litGuideBlurredAnalysis = yes or no; yes if a page is part of a lit guide that has the "blurred analysis" radio button checked in the admin; no if not
    // litGuideBlurredAnalysis: pageProperties.litGuideBlurredAnalysis,

    // litGuidePageSubtype = type of lit guide page (column B here) (only fires for lit guide pages)
    // litGuidePageSubtype: pageProperties.litGuidePageSubtype,

    // litGuideShortStory = yes or no; yes if marked as having just one summary section, no if otherwise (only fires for lit guide pages)
    // litGuideShortStory: pageProperties.litGuideShortStory,

    // pageType = the portion of the URL that appears immediately after the ""www.litcharts.com/"" and before either empty space, a ""/"", or the ""?"" in ""search?query""
    pageType: pageType(),

    // path = path portion of the URL of the page (equivalent to canonical path which defaults to location.pathname from the DOM API)
    path: window.location.pathname,

    // poemLineLength = number of lines in a poem (only fires for poetry guides)
    // TODO

    // pubCohort = mm/yyyy in which guide was published (only fires for lit and poetry guide pages)
    // pubCohort: pageProperties.pubCohort,

    // referrer = full URL of the previous page (equivalent to document.referrer from the DOM API)
    referrer: document.referrer,

    // title = title of the page (equivalent to document.title from the DOM API)
    title: document.title,

    // url = full URL of the page
    url: window.location.href,

    // breakpoint = viewport breakpoint based on page width
    breakpoint: getBreakpoint(),
  };
}

export function logAccountCreated() {
  logEvent("Account Created");
}

export function logAiEvent(eventCategory, toolName) {
  logEvent("AI Tool Event or Action", {
    aiToolEventCategory: eventCategory,
    aiToolEventSubcategory: `${eventCategory} - ${toolName}`,
  });
}

function logSubscriptionStarted(upgradeText = "No") {
  logEvent("Subscription Started", { upgrade: upgradeText });
}

function logPageViewed() {
  logEvent("Page Viewed");
}

function logEvent(e, properties) {
  a().logEvent(e, Object.assign(defaultProperties(), properties));
}

function logSignUpPageViewed() {
  const focus = helpers.getQueryParam("focus");
  const signUpPageType = helpers.getQueryParam("subscription")
    ? "Payment page"
    : "Plan page";

  let planPageFocusType = "Default";
  if (focus?.toLowerCase() === "teacher") {
    planPageFocusType = "APlusTeacher";
  }

  logEvent("Sign Up Page Viewed", {
    // signUpPageGeneralOrFocus = either "General" or "Focus"
    signUpPageGeneralOrFocus: focus ? "Focus" : "General",
    // signUpPageFocusSpecific = if a focused sign up page, what the focus is (use the focus categories we use for the sell page)
    signUpPageFocusSpecific: focus,
    signUpPageType,
    planPageFocusType,
  });
}

function getBreakpoint() {
  const width = window.innerWidth;

  if (width < XXS_PIXEL_MAX) {
    return "XXS";
  }
  if (width <= XS_PIXEL_MAX) {
    return "XS";
  }
  if (width <= SM_PIXEL_MAX) {
    return "Small";
  }
  if (width <= MD_PIXEL_MAX) {
    return "Medium";
  }
  return "Large";
}

function pageType() {
  return window.location.pathname.split(/\/|\?/)[1];
}
