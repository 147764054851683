import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  btnClass: PropTypes.string,
  iconClass: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

const defaultProps = {
  btnClass: "btn-lg btn-primary",
  iconClass: "lc-icon-close lc-icon-xs",
  onClick: () => {},
  text: null,
};

function IconButton(props) {
  const { btnClass, onClick, iconClass, text, ...rest } = props;
  const btnIconClass = text ? "btn-icon-text" : "btn-icon";
  return (
    <button
      className={`btn ${btnIconClass} ${btnClass}`}
      onClick={onClick}
      type="button"
      {...rest}
    >
      <i className={`lc-icon ${iconClass}`} />
      {text && <div>{text}</div>}
    </button>
  );
}

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;
