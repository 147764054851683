// For use as the Option in ReactSelect
//
// Is a copy of Option from the react-select library - https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/components/Option.js
// With extra functionality for adding a checkmark to a selected option and a number icon
import React from "react";
import number from "helpers/number";

const ReactSelectOption = (props) => {
  const {
    children,
    className,
    cx,
    data,
    getValue,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    isMulti,
  } = props;

  const selectHasValue = !isMulti && getValue().length > 0;

  return (
    <div
      className={cx(
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      <div
        className={cx(
          {
            "option--icon-container": true,
            "option--has-value": selectHasValue,
          },
          className
        )}
      >
        {isSelected && <i className="lc-icon lc-icon-checkmark lc-icon-xs" />}
      </div>
      <div className={cx({ "option--inner": true }, className)}>{children}</div>
      {data.numResults !== undefined && (
        <div className="lc-select__num-results">
          {number.commaSeparateNumber(data.numResults)}
        </div>
      )}
    </div>
  );
};

export default ReactSelectOption;
