import React, { useEffect, useState } from "react";
import classnames from "classnames";

// *params list
// arrowX?:           Specifies to align the popover arrow on (left | center | right) [default: left]
// arrowY?:           specifies to align the popover arrow on top or bottom of the popover (Default: top)
// children:          content to render inside the popover
// containerClass?:   optional className to add to the container div
// contentClass?:     optional className to add to the inner content div
// hover?:            adds hover behaviors
// popoverClassName:  wrapper class for the entire popover

export default React.forwardRef((props, ref) => {
  const {
    arrowClass,
    arrowX = "left",
    arrowY = "top",
    children,
    containerClass,
    contentClass,
    hover = false,
    isVisible: isVisibleFromProps,
    popoverClassName,
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const arrowClasses = classnames(
    "arrow",
    { [arrowClass]: arrowClass },
    { top: arrowY === "top" },
    { bottom: arrowY === "bottom" },
    { left: arrowX === "left" },
    { right: arrowX === "right" },
    { center: arrowX === "center" }
  );
  const containerClasses = classnames(
    "popover-container",
    { [containerClass]: containerClass },
    { left: arrowX === "left" },
    { right: arrowX === "right" },
    { center: arrowX === "center" },
    { hidden: !isVisible }
  );
  const contentClasses = classnames("popover-content", {
    [contentClass]: contentClass,
  });
  const isBtn = (e) => {
    return e.type === "button" || e.parentElement.type === "button";
  };

  useEffect(() => {
    const popoverDiv = ref.current;
    const popoverContainer = popoverDiv?.parentElement;

    const encapsulatingDiv = popoverContainer?.parentElement;
    const parentElement = encapsulatingDiv.hasAttribute("data-react-class")
      ? encapsulatingDiv.parentElement
      : encapsulatingDiv;

    const handleClickParent = (event) => {
      if (ref.current.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (
        parentElement?.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        // Parent (usually what the popover is pointing to) is clicked, dismiss the popover
        setIsVisible(false);
      } else if (ref.current.contains(event.target) && isBtn(event.target)) {
        // Popover CTA is clicked, dismiss the popover
        setIsVisible(false);
      }
    };
    parentElement?.addEventListener("click", handleClickParent, true);
    return () => {
      parentElement?.removeEventListener("click", handleClickParent, true);
    };
  }, [ref]);

  useEffect(() => {
    if (!hover) {
      return;
    }

    const popoverDiv = ref.current;
    const popoverContainer = popoverDiv?.parentElement;

    const encapsulatingDiv = popoverContainer?.parentElement;
    const parentElement = encapsulatingDiv.hasAttribute("data-react-class")
      ? encapsulatingDiv.parentElement
      : encapsulatingDiv;

    const handlePopoverParentHover = (event) => {
      // Close popover when the parent element is hovered on
      if (!popoverDiv.contains(event.target)) {
        setIsVisible(false);
        parentElement.removeEventListener(
          "mouseover",
          handlePopoverParentHover
        );
      }
    };

    parentElement.addEventListener("mouseover", handlePopoverParentHover);
    return () => {
      parentElement.removeEventListener("mouseover", handlePopoverParentHover);
    };
  }, [hover, ref]);

  useEffect(() => {
    setIsVisible(isVisibleFromProps);
  }, [isVisibleFromProps]);

  return (
    <div className={popoverClassName}>
      <div
        ref={ref}
        className={containerClasses}
        data-test="popover"
        role="tooltip"
      >
        <div className={arrowClasses} />
        <div className={contentClasses}>{children}</div>
      </div>
    </div>
  );
});
