// Use this when having to create complicated inputs. See /dev/components/inputs for details

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import LabelContainer from "components/shared/LabelContainer";
import IconButton from "components/shared/IconButton";
import { logAmplitudeCtaEvent } from "lib/shared/events";

const propTypes = {
  buttonVariant: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hideSubtext: PropTypes.bool,
  inputClass: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  amplitudeKey: PropTypes.string,
};

const defaultProps = {
  buttonVariant: false,
  disabled: false,
  errorMessage: null,
  hideSubtext: false,
  inputClass: "input-lg",
  max: null,
  min: null,
  onChange: () => {},
  onSubmit: () => {},
  placeholder: "",
  value: "",
  amplitudeKey: null,
};

const InputTextCounter = (props) => {
  const {
    buttonVariant,
    amplitudeKey,
    disabled,
    locked,
    errorMessage: errorFromProps,
    hideSubtext,
    inputClass,
    min,
    max,
    name,
    onChange,
    onSubmit,
    placeholder,
    value,
    ...labelProps
  } = props;
  const [internalErrorMessage, setInternalErrorMessage] = useState();
  const [currentValue, setCurrentValue] = useState(value);
  const [leftSubcopyText, setLeftSubcopyText] = useState("");
  const [rightSubcopyText, setRightSubcopyText] = useState("");
  const errorMessage = internalErrorMessage || errorFromProps;

  const validate = () => {
    const textLength = currentValue.length;

    if (min && textLength < min) {
      setLeftSubcopyText(`${min} characters required`);
      if (textLength > 0) {
        setInternalErrorMessage(`${min} characters required`);
      } else {
        setInternalErrorMessage(null);
      }
    } else if (max && textLength > max) {
      setInternalErrorMessage("Over max character limit");
    } else {
      setLeftSubcopyText("");
      setInternalErrorMessage(null);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setCurrentValue(newValue);

    onChange && onChange(e);
  };

  const handleKeyDown = (event) => {
    if (buttonVariant && event.key === "Enter") {
      event.preventDefault();
      onSubmit();

      // Note: this is to track down submitting a question via the Enter key
      logAmplitudeCtaEvent(amplitudeKey);
    }
  };

  const disableButton = () => {
    if (locked) {
      // When a user is free tier or logged out, we never
      // disable the button, so that they get redirected
      // to the sign up page.
      return false;
    }

    if (errorMessage || leftSubcopyText) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!min) return;

    if (currentValue.length < min) {
      setLeftSubcopyText(`${min} characters required`);
    } else if (!max || currentValue.length <= max) {
      setLeftSubcopyText("");
      setInternalErrorMessage(null);
    }
  }, [min, currentValue]);

  useEffect(() => {
    if (!max) return;

    if (currentValue.length > max) {
      setInternalErrorMessage("Over max character limit");
    } else if (!min || currentValue.length >= min) {
      setLeftSubcopyText("");
      setInternalErrorMessage(null);
    }

    setRightSubcopyText(`${currentValue.length}/${max}`);
  }, [max, currentValue]);

  return (
    <div className="input-container">
      <LabelContainer {...labelProps} hasError={errorMessage} name={name} />
      <div
        className={classnames("text-area-container", {
          buttonVariant,
        })}
      >
        <TextareaAutosize
          className={classnames("input-text-counter--input", inputClass, {
            error: errorMessage,
            buttonVariant,
          })}
          data-testid="input-text-counter"
          disabled={disabled}
          minRows={1}
          name={name}
          onBlur={validate}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={disabled ? "" : placeholder}
          type="text"
          value={currentValue}
        />
        {buttonVariant && !disabled && (
          <IconButton
            iconClass="lc-icon-arrow-up lc-icon-xs"
            btnClass="btn-xs btn-primary pill"
            disabled={disableButton()}
            onClick={onSubmit}
            data-amplitude={amplitudeKey}
          />
        )}
      </div>
      {!hideSubtext && (
        <div className="input-container--subcopy">
          <div
            className={
              errorMessage ? "input__error-message" : "input__sub-message"
            }
            data-testid="text-counter-left-subcopy"
          >
            {errorMessage || leftSubcopyText}
          </div>
          <div
            className={
              errorMessage ? "input__error-message" : "input__sub-message"
            }
            data-testid="text-counter-right-subcopy"
          >
            {rightSubcopyText}
          </div>
        </div>
      )}
    </div>
  );
};

InputTextCounter.defaultProps = defaultProps;
InputTextCounter.propTypes = propTypes;

export default InputTextCounter;
