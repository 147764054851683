import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  classModifier: PropTypes.string,
  labelClass: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  classModifier: null,
  labelClass: "input__label",
  labelText: "",
};

const Label = ({ classModifier, labelClass, labelText, name }) => {
  const className = classModifier
    ? `${labelClass} ${classModifier}`
    : labelClass;

  return (
    <label className={className} htmlFor={name}>
      {labelText}
    </label>
  );
};

Label.defaultProps = defaultProps;
Label.propTypes = propTypes;

export default Label;
