// Large: 1200px+
// Medium: 992-1199px
// Small: 768-991px
// XS: 576-767px
// XXS: <576px

export const XXS_PIXEL_MAX = 576;
export const XS_PIXEL_MAX = 767;
export const SM_PIXEL_MIN = 768;
export const SM_PIXEL_MAX = 991;
export const MD_PIXEL_MAX = 1199;
