import React from "react";
import Select from "components/shared/Select";

const VariantCountrySelect = (props) => {
  const onChange = (value) => {
    $(".marketing-optin-checkbox").toggleClass(
      "hidden",
      !value.conforms_to_gdpr
    );
    $("input[name='user[email_marketing_optin]']").prop(
      "checked",
      !value.conforms_to_gdpr
    );
  };

  return <Select {...props} onChange={onChange} />;
};

export default VariantCountrySelect;
