import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { defaultToastConfig, toastNotify } from "../../helpers/toastNotify";
import IconButton from "../shared/IconButton";
import { publish, subscribe, unsubscribe } from "../../helpers/pubsub";

const toasterDelay = 800;
// Popover should appear *after* toaster disappears.
const popoverDelay =
  toasterDelay + defaultToastConfig.toastTimeout + defaultToastConfig.autoClose;

export default function BookmarkButton({
  bookmarkableType,
  bookmarkableId,
  buttonClass,
  amplitudeContext,
  hideSuccessCta = false,
  initialBookmarkId,
  onRemoval = null,
  userSignedIn,
}) {
  const [isBookmarked, setIsBookmarked] = useState(!!initialBookmarkId);
  const [bookmarkId, setBookmarkId] = useState(initialBookmarkId);
  const bookmarkIcon = isBookmarked ? "bookmark-filled" : "bookmark";
  const amplitudeEvent = isBookmarked
    ? `removeGuideOn${amplitudeContext}`
    : `saveGuideOn${amplitudeContext}`;
  const [isLoading, setIsLoading] = useState(false);

  // BEGIN USER NOT SIGNED IN
  // (paul) We need a way to both trigger the bootstrap modal and set some session variables to
  // save the bookmarkable after the user has been created / signed in. Like the event handling below,
  // this is kind of a hacky solution. Can't figure out a better way with how the app currently
  // works, so we'll add some data attributes on the button and fire off a post request to save the session
  // via an onClick handler.

  if (!userSignedIn) {
    const dataAttrs = {
      "data-toggle": "modal",
      "data-target": "#bookmark-registration-modal .modal",
      "data-amplitude": amplitudeEvent,
    };

    const onClick = () => {
      $.ajax({
        data: {
          bookmark_session: {
            bookmarkable_id: bookmarkableId,
            bookmarkable_type: bookmarkableType,
          },
        },
        dataType: "json",
        method: "POST",
        url: "/bookmark_sessions",
      });
    };

    return (
      <IconButton
        btnClass={`btn-icon btn-lg bookmark-btn ${buttonClass}`}
        {...dataAttrs}
        iconClass={`lc-icon lc-icon-${bookmarkIcon} lc-icon-sm`}
        onClick={onClick}
      />
    );
  }

  // BEGIN EVENT HANDLING //

  // (alan) This was meant to maintain bookmark state across LitCards that can show up twice
  // on a single page (ex. /lit and /poetry pages where the LitCard for a guide
  // can be visible twice on the page). Because the Lit Cards on these pages don't share
  // the same tree of React Components, we can't share state between them.
  // While this is definitely hacky, there are no other alternatives without a rewrite
  // of the entire view to be fully React OR introducing some other means of sharing state between
  // isolated components under the current paradigm.

  const eventKey = `${bookmarkableType}-${bookmarkableId}-event`;

  const handleBookmarkEvent = (event) => {
    setIsBookmarked(event.detail.isBookmarked);
    setBookmarkId(event.detail.id);
  };

  useEffect(() => {
    subscribe(eventKey, handleBookmarkEvent);

    return () => {
      unsubscribe(eventKey, handleBookmarkEvent);
    };
  }, []);

  // END EVENT HANDLING //

  const handleOnCreateBookmark = (event) => {
    if (isLoading) return;
    setIsLoading(true);
    event.stopPropagation();

    const loadingTimeout = setTimeout(
      () => toastNotify("Saving..."),
      toasterDelay
    );
    $.ajax({
      data: {
        bookmarkable_id: bookmarkableId,
        bookmarkable_type: bookmarkableType,
      },
      dataType: "json",
      method: "POST",
      url: "/bookmarks",
      success: (data) => {
        clearTimeout(loadingTimeout);
        toastNotify("Guide saved", !hideSuccessCta && <ViewAllCta />);
        publish(eventKey, { isBookmarked: true, id: data.id });

        if (data.showPopover) {
          setTimeout(
            () => publish("displayBookmarksFeaturePopover", {}),
            popoverDelay
          );
        }
      },
      error: () => {
        clearTimeout(loadingTimeout);
        toastNotify("Guide failed to save. Please try again.");
      },
      complete: () => {
        setIsLoading(false);
      },
    });
  };

  const handleOnRemoveBookmark = (event) => {
    if (isLoading) return;
    setIsLoading(true);
    event.stopPropagation();

    const loadingTimeout = setTimeout(
      () => toastNotify("Removing..."),
      toasterDelay
    );
    $.ajax({
      dataType: "json",
      method: "DELETE",
      url: `/bookmarks/${bookmarkId}`,
      success: () => {
        clearTimeout(loadingTimeout);
        toastNotify("Guide removed", !hideSuccessCta && <ViewAllCta />);
        if (onRemoval) onRemoval(bookmarkId);
        publish(eventKey, { isBookmarked: false, id: null });
      },
      error: () => {
        clearTimeout(loadingTimeout);
        toastNotify("Guide failed to remove. Please try again.");
      },
      complete: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <IconButton
      data-amplitude={amplitudeEvent}
      btnClass={`btn-icon btn-lg bookmark-btn ${buttonClass}`}
      iconClass={`lc-icon lc-icon-${bookmarkIcon} lc-icon-sm`}
      onClick={isBookmarked ? handleOnRemoveBookmark : handleOnCreateBookmark}
    />
  );
}

BookmarkButton.propTypes = {
  bookmarkableType: PropTypes.string.isRequired,
  bookmarkableId: PropTypes.number.isRequired,
  buttonClass: PropTypes.string.isRequired,
  hideSuccessCta: PropTypes.bool,
  initialBookmarkId: PropTypes.string,
  onRemoval: PropTypes.func,
  userSignedIn: PropTypes.bool.isRequired,
};

function ViewAllCta() {
  return (
    <a data-amplitude="toasterViewAll" href="/account/saved-guides">
      View all
    </a>
  );
}
