import React from "react";
import PropTypes from "prop-types";

import Label from "components/shared/Label";
import RequiredText from "components/shared/RequiredText";

const propTypes = {
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

const defaultProps = {
  isDisabled: false,
  isRequired: false,
};

const LabelContainer = (props) => {
  let classModifier = null;

  if (props.isDisabled) {
    classModifier = "disabled";
  }

  if (!props.labelText) {
    // In-Content AI will not pass label text
    // when no label is necessary.
    return null;
  }

  if (props.isRequired) {
    return (
      <RequiredText
        classModifier={classModifier}
        requiredText={props.requiredText}
        requiredTextClass={props.requiredTextClass}
      >
        <Label
          classModifier={classModifier}
          name={props.name}
          labelClass={props.labelClass}
          labelText={props.labelText}
        />
      </RequiredText>
    );
  }

  return (
    <Label
      classModifier={classModifier}
      name={props.name}
      labelClass={props.labelClass}
      labelText={props.labelText}
    />
  );
};

LabelContainer.propTypes = propTypes;
LabelContainer.defaultProps = defaultProps;

export default LabelContainer;
