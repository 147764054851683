import React, { useEffect, useRef, useState } from "react";
import LcOAuth2 from "lib/shared/oauth2";
import PropTypes from "prop-types";
import ResizeObserver from "resize-observer-polyfill";
import AppBanner from "./AppBanner";

const propTypes = {
  minWidthInPixels: PropTypes.number,
  maxWidthInPixels: PropTypes.number,
  onClick: PropTypes.func,
  onSkipFormAndCreateAccount: PropTypes.func,
  onRegistrationInProgress: PropTypes.func,
  registrationInProgressEvent: PropTypes.shape({ type: PropTypes.string }),
  openRequestLitGuideModal: PropTypes.bool,
  flow: PropTypes.string,
  text: PropTypes.string,
  subscription: PropTypes.string,
};

const defaultProps = {
  minWidthInPixels: 200, // 200px _seems_ to be the minimum width accepted by Google, could not find it documented.
  maxWidthInPixels: 400, // Largest width permitted by Google is 400px.
  onClick: () => {}, // no-op
  onRegistrationInProgress: () => {}, // no-op
  registrationInProgressEvent: null,
  openRequestLitGuideModal: false,
  flow: null,
  subscription: null,
  text: "continue_with", // See available options at: https://developers.google.com/identity/gsi/web/reference/js-reference#text
};

const setErrorMessage = (message) => {
  AppBanner.setContent(message);
};

function GoogleAuthButton(props) {
  const {
    minWidthInPixels,
    maxWidthInPixels,
    onClick,
    onSkipFormAndCreateAccount,
    onRegistrationInProgress,
    registrationInProgressEvent,
    flow,
    text,
    subscription,
  } = props;
  const buttonContainer = useRef(null);
  const [widthInPixels, _setWidthInPixels] = useState(0);
  const [googleInitialized, setGoogleInitialized] = useState(false);

  const callbackUrlRef = useRef("");

  // We do this funky assignment here of 'callbackUrlRef.current' to avoid adding 'subscription' and 'flow'
  // into the dependency array in the useEffect where 'callbackUrlRef.current' is referenced.

  useEffect(() => {
    const callbackUrl = buildCallbackUrl();
    callbackUrlRef.current = callbackUrl;
  }, [subscription, flow]);

  const setWidthInPixels = (width) => {
    if (width <= 0) {
      // Don't bother changing widthInPixels if it would be set to zero or less.
      return;
    }
    let newWidth = width;
    if (width < minWidthInPixels) {
      newWidth = minWidthInPixels;
    } else if (width > maxWidthInPixels) {
      newWidth = maxWidthInPixels;
    }
    // Round width down to fix bug where fractional button widths are ignored on some devices (Pixel 3 - Pixel 7)
    newWidth = Math.floor(newWidth);
    _setWidthInPixels(newWidth);
  };

  const skipFormAndCreateAccount = () => {
    $.ajax({
      data: {
        signup_origin: "free_tier_modal",
        user: { simplified_signup: true, email_marketing_optin: true },
      },
      dataType: "json",
      method: "POST",
      url: "/",
      success: (_data, _textStatus, jqXHR) => {
        window.location.href = jqXHR.getResponseHeader("Location");
      },
    });
  };

  const buildCallbackUrl = () => {
    const callbackUrl = "/auth/google_one_tap/callback";
    const params = [];

    if (flow) {
      params.push(`flow=${flow}`);
    }

    if (subscription) {
      params.push(`subscription=${subscription}`);
    }

    return callbackUrl + (params.length ? `?${params.join("&")}` : "");
  };

  useEffect(() => {
    const handleCredentialResponse = (event) => {
      const { response } = event;
      Object.assign(response, {
        open_request_lit_guide_modal: props.openRequestLitGuideModal,
      });

      const callbackUrl = callbackUrlRef.current;

      $.ajax({
        method: "POST",
        // TODO: Use more general strategy name than google_one_tap *but* still record if user signed up with one-tap or otherwise
        url: callbackUrl,
        data: response,
        success: (data, _textStatus, jqXHR) => {
          switch (data.state) {
            case "skip_form_and_create_account":
              if (onSkipFormAndCreateAccount) {
                onSkipFormAndCreateAccount();
              } else {
                skipFormAndCreateAccount();
              }
              break;
            case "registration_in_progress":
              onRegistrationInProgress();
              if (registrationInProgressEvent) {
                $(buttonContainer.current).trigger(registrationInProgressEvent);
              }
              break;
            case "signed_in": // A previously registered user has signed in.
            case "sign_in_elsewhere": // User needs to sign in on another page
              window.location.href = jqXHR.getResponseHeader("Location");
              break;
            default:
              // eslint-disable-next-line no-console
              console.log(
                "There was an error authenticating with Google, unrecognized state."
              );
          }
        },
        error: (jqXHR, _textStatus, _errorThrown) => {
          // Happens if Users::OmniauthCallbacksController#failure responds.
          setErrorMessage(jqXHR.responseJSON.error);
        },
      });
    };
    $(buttonContainer.current).on(
      "googleIdButton:credentialResponse",
      handleCredentialResponse
    );

    const resizeObserver = new ResizeObserver((entries) => {
      const resizedWidth = entries[0].contentRect.width;
      setWidthInPixels(resizedWidth);
    });
    resizeObserver.observe(buttonContainer.current);

    LcOAuth2.initGoogle().then(() => {
      setGoogleInitialized(true);
    });

    // Before showing the modal, to prevent glitching/flickering of the button, we calculate its width and render it.
    //
    // calcWidthInvisibly is currently hardcoded with the assumptions:
    // - the button is inside a modal
    // - the modal is hidden with 'display: none'
    //
    // This toggles 'visibility: hidden' on only briefly to calculate the button width, whilst still hiding the modal.
    // With 'display: none' an element is not allocated space. With 'visibility: hidden', the element is allocated space
    // and so allows dimensions to be calculated.
    //
    // If the button is not inside a hidden modal, then this function does nothing and is harmless.
    const calcWidthInvisibly = () => {
      const $modal = $(buttonContainer.current)
        .closest(".modal")
        .filter(":hidden");
      if ($modal.length > 0) {
        const originalVisibility = $modal.css("visibility");
        // Temporarily set 'visibility: hidden' AND remove 'display: none' so the modal stays invisible but is
        // allocated space, which we can use to get a non-zero width value.
        $modal.css("visibility", "hidden").show();
        // Set the width of the button.
        setWidthInPixels($(buttonContainer.current).width());
        // Return visibility and display to their original values.
        $modal.hide().css("visibility", originalVisibility);
      }
    };

    if (document.readyState === "complete") {
      // Load event already fired, calc width immediately.
      calcWidthInvisibly();
    } else {
      // Load event not yet fired, add event listener.
      window.addEventListener("load", calcWidthInvisibly);
    }

    // Returning useEffect to cleanup listeners and observers.
    return () => {
      window.removeEventListener("load", calcWidthInvisibly);
      resizeObserver.disconnect();
      $(buttonContainer.current).off(
        "googleIdButton:credentialResponse",
        handleCredentialResponse
      );
    };
  }, []); // Empty dependencies array so this runs only once when component mounts.

  const renderButton = () => {
    if (!googleInitialized || widthInPixels <= 0) {
      // To render button, google initialization must be complete, and width in pixels must be greater than 0.
      return;
    }

    $(buttonContainer.current).empty(); // Removes any existing button

    // Button options: https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
    window.google.accounts.id.renderButton(buttonContainer.current, {
      type: "standard",
      theme: "filled_blue",
      size: "large",
      text,
      shape: "rectangular",
      logo_alignment: "left",
      width: widthInPixels,
      click_listener: () => {
        // We record this is the last GoogleAuthButton to be clicked so the "googleIdButton:credentialResponse" event
        // is triggered on this same button, which is useful if there are multiple GoogleAuthButtons on the page.
        LcOAuth2.googleAuthButtonLastClicked = buttonContainer.current;
        onClick();
        $(buttonContainer.current).trigger("click");
        setErrorMessage(null);
      },
    });
  };
  // Re-renders button when width changes. Width changes when modal opens, and when window resized.
  useEffect(renderButton, [googleInitialized, widthInPixels]);

  return (
    <div className="google-auth-button-parent">
      <div className="google-auth-button" ref={buttonContainer} />
    </div>
  );
}

GoogleAuthButton.propTypes = propTypes;
GoogleAuthButton.defaultProps = defaultProps;

export default GoogleAuthButton;
