import { initializePack } from "lib/shared/packs";
import detectUserInput from "initializers/detectUserInput";
import events from "initializers/events";
import view from "initializers/view";
import toast from "initializers/toast";
import globalViews from "initializers/globalViews";
import applyUtilityClasses from "initializers/applyUtilityClasses";

initializePack({
  initializers: [
    detectUserInput,
    events,
    view,
    toast,
    globalViews,
    applyUtilityClasses,
  ],
  components: require.context(
    "components",
    true,
    /^\.\/navigation|(shared\/(AppBanner|GoogleAuthButton|Input|FacebookLoginButton|BirthdayInput|Checkbox|GoogleAuthInput|Modal|Select|LitCard|Toaster|Banner|SessionTimeoutModal|BeforeYouBeginModal|GoogleOneTap|AskAIPopover|AIToolsPopover|BookmarksFeaturePopover|HighlightsAndNotesPopover|SignUpModal|RecentlyAddedGuides|dialogs\/CookieOptIn))|sell\/TermsCheckbox/ //eslint-disable-line max-len
  ),
});
