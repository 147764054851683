import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  classModifier: PropTypes.string,
  requiredText: PropTypes.string,
  requiredTextClass: PropTypes.string,
  children: PropTypes.element.isRequired,
};

const defaultProps = {
  classModifier: "",
  requiredText: "Required",
  requiredTextClass: "required-text__text",
};

// children should be a label component
const RequiredText = ({
  classModifier,
  requiredText,
  requiredTextClass,
  children,
}) => {
  const className = classModifier
    ? `${requiredTextClass}--${classModifier}`
    : requiredTextClass;
  return (
    <div className="required-text">
      <span className="required-text__label-container">{children}</span>
      <span className="required-text__container--inner">
        <span className={className}>{requiredText}</span>
      </span>
    </div>
  );
};

RequiredText.propTypes = propTypes;
RequiredText.defaultProps = defaultProps;

export default RequiredText;
