import React from "react";
import Checkbox from "components/shared/Checkbox";

export default function TermsCheckbox(props) {
  return (
    <Checkbox {...props}>
      <span>By checking here you agree to our </span>
      <a href={props.url} target="_blank" rel="noreferrer">
        Terms of Service
      </a>
      <span> and have read our </span>
      <a href={props.privacyPolicyUrl} target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      {props.variation ? (
        <span>
          , and that you are starting a recurring subscription with a 24-hour
          free trial.
        </span>
      ) : (
        <span>.</span>
      )}
    </Checkbox>
  );
}
